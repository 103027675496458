import { Injectable, InjectionToken, PLATFORM_ID, LOCALE_ID, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, combineLatest, ReplaySubject, EMPTY, merge, of, iif } from 'rxjs';
import { distinctUntilChanged, debounceTime, map, shareReplay, switchMap, catchError, tap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { SettingsService } from './settings.service';


export interface HaupiaServiceConfigurationInterface {
  baseUrl: URL;
  onServer?: {
    baseUrl?: URL;
  };
}

export const haupiaServiceConfiguration = new InjectionToken<HaupiaServiceConfigurationInterface>('haupiaServiceConfiguration');

interface HaupiaResponse<
  HaupiaResponsRowType extends HaupiaResponseRowResult,
  HaupiaResponseRowHighlightingType extends HaupiaResponseRowHighlighting,
  HaupiaResponseFacetType extends HaupiaResponseFacet,
> {
  numRows: number;
  didYouMean: string[];
  results: HaupiaResponsRowType[];
  highlighting: {
    [id: string]: HaupiaResponseRowHighlightingType;
  };
  facetConfigs: {
    baseName: keyof HaupiaResponseFacetType;
    multiSelect: boolean;
  }[];
  facets: {
    counts: Facet<HaupiaResponseFacetType[keyof HaupiaResponseFacetType]>;
    name: keyof HaupiaResponseFacetType;
  }[];
}

export type HaupiaValue = string | [string] | number;

export type HaupiaResponseRowResult = {
  link: string;
  id: string;
};

export interface HaupiaResponseRowHighlighting {
  [key: string]: HaupiaValue;
}

export interface PreparedSearchResultRow<
  HaupiaResponseRowResultType extends HaupiaResponseRowResult,
  HaupiaResponseRowHighlightingType extends HaupiaResponseRowHighlighting,
> {
  result: HaupiaResponseRowResultType;
  highlighting: HaupiaResponseRowHighlightingType;
}

type PreparedSearchResults<
  HaupiaResponseRowResultType extends HaupiaResponseRowResult,
  HaupiaResponseRowHighlightingType extends HaupiaResponseRowHighlighting,
  HaupiaResponseFacetType extends HaupiaResponseFacet,
> = {
  rows: PreparedSearchResultRow<HaupiaResponseRowResultType, HaupiaResponseRowHighlightingType>[];
  numRows: number | null;
  facets: Facets<HaupiaResponseFacetType>;
};

export type HaupiaResponseFacet = { [facetName: string]: string };

export type Facets<HaupiaResponseFacetType extends HaupiaResponseFacet> = { [facetName in keyof HaupiaResponseFacetType]: Facet<HaupiaResponseFacetType[keyof HaupiaResponseFacetType]> };

export type Facet<HaupiaResponseFacetValueType extends string> = {
  value: HaupiaResponseFacetValueType;
  count?: number;
  urlFilterQuery: string;
}[];

export function getFirstValue(...haupiaValues: (HaupiaValue | undefined)[]) : string {
  for (const haupiaValue of haupiaValues) {
    if (Array.isArray(haupiaValue)) {
      if (haupiaValue[0]) {
        return haupiaValue[0];
      }
      continue;
    }
    if (typeof haupiaValue === 'string' && haupiaValue) {
      return haupiaValue;
    }
    if (typeof haupiaValue === 'number') {
      return haupiaValue.toString();
    }
  }
  return '';
}

@Injectable({
  providedIn: 'root',
})
export class HaupiaService {

  private http = inject(HttpClient);
  private settingsService = inject(SettingsService);
  private platformId = inject(PLATFORM_ID);

  private serviceBaseUrl: string;

  public readonly target = this.settingsService.overrideHaupiaTarget;

  private readonly locale = inject(LOCALE_ID);

  public constructor() {
    const configuration = inject(haupiaServiceConfiguration);
    const baseUrl = (configuration.onServer?.baseUrl && isPlatformServer(this.platformId) ? configuration.onServer.baseUrl : configuration.baseUrl)
    this.serviceBaseUrl = baseUrl.toString();
  }

  public prepareSearch<
    HaupiaResponseRowResultType extends HaupiaResponseRowResult,
    HaupiaResponseRowHighlightingType extends HaupiaResponseRowHighlighting,
    HaupiaResponseFacetType extends HaupiaResponseFacet,
  >(
    name: string,
    fetchSize: number,
    languageDependant = true,
  ) {
    return new PreparedSearch<
      HaupiaResponseRowResultType,
      HaupiaResponseRowHighlightingType,
      HaupiaResponseFacetType
    >(this, this.locale, name, fetchSize, languageDependant);
  }

  public fetch<
    HaupiaResponseRowResultType extends HaupiaResponseRowResult,
    HaupiaResponseRowHighlightingType extends HaupiaResponseRowHighlighting,
    HaupiaResponseFacetType extends HaupiaResponseFacet,
  >(url: string): Observable<
    HaupiaResponse<
      HaupiaResponseRowResultType,
      HaupiaResponseRowHighlightingType,
      HaupiaResponseFacetType
    >
  > {
    return this.http.get<
      HaupiaResponse<
        HaupiaResponseRowResultType,
        HaupiaResponseRowHighlightingType,
        HaupiaResponseFacetType
      >
    >(this.serviceBaseUrl + url).pipe(
      catchError(() => EMPTY), // TODO: properly handle error, error is logged with http-logger.interceptor
    );
  }

}

export class PreparedSearch<
  HaupiaResponseRowResultType extends HaupiaResponseRowResult,
  HaupiaResponseRowHighlightingType extends HaupiaResponseRowHighlighting,
  HaupiaResponseFacetType extends HaupiaResponseFacet,
> {

  public readonly results$: Observable<PreparedSearchResults<HaupiaResponseRowResultType, HaupiaResponseRowHighlightingType, HaupiaResponseFacetType>>;
  public readonly loading$ = new ReplaySubject<boolean>(1);

  private resultRows: PreparedSearchResultRow<HaupiaResponseRowResultType, HaupiaResponseRowHighlightingType>[] = [];
  private numRows: number | null = null;
  private facets?: Facets<HaupiaResponseFacetType>;

  private additionalParameters = new Map<string, string[]>();
  private additionalQueryString$ = new BehaviorSubject<string>('');

  private facetFilter = new Map<keyof HaupiaResponseFacetType, Facet<HaupiaResponseFacetType[keyof HaupiaResponseFacetType]>>();
  private facetFilterQueryString$ = new BehaviorSubject<string>('');

  private query$ = new ReplaySubject<string>(1);
  private page$ = new BehaviorSubject<number>(1);
  private sortQuery$ = new BehaviorSubject<string>('');
  private pos$ = new BehaviorSubject<string>('');
  private radius$ =  new BehaviorSubject<string>('');

  public constructor(
    private haupiaService: HaupiaService,
    private locale: string,
    preparedSearchName: string,
    fetchSize: number,
    languageDependant = true,
  ) {

    this.results$ = combineLatest([
      this.query$, this.page$.pipe(distinctUntilChanged()), this.facetFilterQueryString$, this.additionalQueryString$,
      this.sortQuery$, this.haupiaService.target, this.pos$, this.radius$,
    ]).pipe(
      debounceTime(10),
      map(([
        query, page, facetFilterQueryString, additionalQueryString,
        sortQuery, target, pos, radius,
      ]) => {

        let localeFilter = '';
       if (languageDependant) {
       /*    localeFilter = `&language=${this.locale}`;
          if (this.locale !== 'en' && this.locale !== 'de') {
            localeFilter = `&language=en,en`;
          }
            */
           // TODO !!!  CP0166185

         localeFilter = 'en' === (this.locale) ? `&language=${this.locale}`: `&language=${this.locale},en`;
        }
        let url =  `${preparedSearchName}?query=${query}${localeFilter}&rows=${fetchSize}&page=${page}${facetFilterQueryString}${additionalQueryString}${sortQuery}&target=${target}`;
        if (pos) {
          url = `${url}&pos=${pos}`;
        }
        if (radius) {
          url = `${url}&radius=${radius}`;
        }
        return url;
      }),
      distinctUntilChanged(),
      tap(() => {
        this.loading$.next(true);
      }),
      switchMap((nameWithParameters) => merge(
        iif(
          () => this.facets !== undefined,
          of({
            rows: this.resultRows,
            numRows: this.numRows,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            facets: this.facets!,
          } satisfies PreparedSearchResults<HaupiaResponseRowResultType, HaupiaResponseRowHighlightingType, HaupiaResponseFacetType>),
          EMPTY,
        ),
        this.haupiaService.fetch<
          HaupiaResponseRowResultType,
          HaupiaResponseRowHighlightingType,
          HaupiaResponseFacetType
        >(nameWithParameters).pipe(
          map((response) => {
            this.facets = Object.fromEntries(
              response.facets.map((facet, index) => [response.facetConfigs[index].baseName, facet.counts]),
            ) as Facets<HaupiaResponseFacetType>;

            for (const result of response.results) {
              this.resultRows.push({
                result,
                highlighting: response.highlighting[result.id],
              });
            }

            this.numRows = response.numRows;

            return {
              rows: this.resultRows,
              numRows: this.numRows,
              facets: this.facets,
            };
          }),
          tap(() => {
            this.loading$.next(false);
          }),
        ),
      )),
      shareReplay(1),
    );
  }

  public close(): void {
    this.query$.complete();
    this.page$.complete();
    this.additionalQueryString$.complete();
    this.sortQuery$.complete();
    this.pos$.complete();
    this.radius$.complete();
  }

  public setQuery(query: string): void {
    this.reset();

    if (query === '') {
      query = '*';
    }

    this.query$.next(encodeURIComponent(query.trim()));
  }

  public setSorting(sortKey: string, desc = false): void {
    this.reset();
    this.sortQuery$.next(`&sortBy=${sortKey}&sortOrder=${desc ? 'desc' : 'asc'}`);
  }

  public getSortQueryValue(): string {
    return this.sortQuery$.getValue();
  }

  public removeSorting(): void {
    this.reset();
    this.sortQuery$.next('');
  }


  public setPos(pos: string, radius: number): void {
    this.reset();
    if (pos === '') {
      pos = '*';
    }
    this.pos$.next(`${pos}`);
    this.setRadius(radius);
  }

  public removePos(): void {
    this.reset();
    this.pos$.next('');
  }


  public setRadius(radius: number): void {
    this.reset();
    this.radius$.next(`${radius}`);
  }

  public removeRadius(): void {
    this.reset();
    this.radius$.next('');
  }

  public getFacetFilter(name: keyof HaupiaResponseFacetType): Facet<HaupiaResponseFacetType[keyof HaupiaResponseFacetType]> | undefined {
    return this.facetFilter.get(name);
  }

  public setFacetFilterManually(name: keyof HaupiaResponseFacetType, values: HaupiaResponseFacetType[keyof HaupiaResponseFacetType][]): void {
    this.facetFilter.set(name, values.map(value => ({
      value: value,
      count: undefined,
      urlFilterQuery: `facet.filter.${name.toString()}=${encodeURIComponent(value)}`,
    })));
    this.updateFacetFilterQueryString();
  }

  public setFacetFilter(name: keyof HaupiaResponseFacetType, facet: Facet<HaupiaResponseFacetType[keyof HaupiaResponseFacetType]>): void {
    this.facetFilter.set(name, facet);
    this.updateFacetFilterQueryString();
  }

  public removeFacetFilter(name: keyof HaupiaResponseFacetType): void {
    this.facetFilter.delete(name);
    this.updateFacetFilterQueryString();
  }

  private updateFacetFilterQueryString(): void {
    let queryString = '';
    for (const [, facets] of Array.from(this.facetFilter)) {
      for (const facet of facets) {
        queryString += `&${facet.urlFilterQuery}`;
      }
    }

    this.reset();
    this.facetFilterQueryString$.next(queryString);
  }

  public setParameter(name: string, values: string[]): void {
    this.additionalParameters.set(name, values);
    this.updateAdditionalQueryString();
  }

  public removeParameter(name: string): void {
    this.additionalParameters.delete(name);
    this.updateAdditionalQueryString();
  }

  public next(): void {
    this.page$.next(this.page$.value + 1);
  }

  private updateAdditionalQueryString(): void {
    let queryString = '';
    for (const [name, values] of Array.from(this.additionalParameters)) {
      for (const value of values) {
        queryString += `&${name}=${encodeURIComponent(value)}`;
      }
    }

    this.reset();
    this.additionalQueryString$.next(queryString);
  }

  private reset(): void {
    this.resultRows = [];
    this.numRows = null;
    this.page$.next(1);
  }

}
